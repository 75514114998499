<template>
  <div class="page">
    <!-- <div class="stamp">
			<el-button @click="goHis" type="text">
				回退
			</el-button>
		</div> -->
    <el-descriptions class="margin-top descriptionsItem" :column="3" border>
      <el-descriptions-item>
        <template slot="label">
          电梯注册代码
        </template>
        {{dioData.elevatorInfo ? dioData.elevatorInfo.code : ''}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          电梯名称
        </template>
        {{dioData.elevatorInfo ? dioData.elevatorInfo.name : ''}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          救援识别码
        </template>
        {{dioData.rescueCode}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          物业单位
        </template>
        {{ dioData.elevatorInfo ? dioData.elevatorInfo.propertyUnitName :''}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          维保单位
        </template>
        {{dioData.elevatorInfo ? dioData.elevatorInfo.maintainUnitName : ''}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          电梯出厂编号
        </template>
        {{dioData.factoryNumber}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          计划时间
        </template>
        {{$formatDate(new Date(dioData.startTime),format = 'yyyy-MM-dd')}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          维保类型
        </template>
        {{dioData.maintainTypeName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          完成时间
        </template>
        {{dioData.finishTime}}
      </el-descriptions-item>
    </el-descriptions>
    <!-- <el-descriptions class="margin-top descriptionsItem" :column="3" border v-else>
      <el-descriptions-item>
        <template slot="label">
          订单编号
        </template>
        {{dioData.id}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          订单价格
        </template>
        {{dioData.price}}元
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          订单状态
        </template>
        {{orderList.filter(item=>item.value == dioData.orderStatus)[0]?.label}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          电梯名称
        </template>
        {{dioData.eleName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          小区
        </template>
        {{dioData.plotName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          维保类型
        </template>
        {{dioData.maintainTypeName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          维保日期
        </template>
        {{$formatDate(new Date(dioData.startTime),format = 'yyyy-MM-dd')}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          维保单位
        </template>
        {{dioData.maintainUnitName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          物业单位
        </template>
        {{dioData.propertyUnitName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          创建时间
        </template>
        {{dioData.createTime}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          付款时间
        </template>
        {{dioData.payTime}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          完成时间
        </template>
        {{dioData.finishTime}}
      </el-descriptions-item>
    </el-descriptions> -->


    <el-descriptions class="margin-top descriptionsRow" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          电梯安装位置
        </template>
        {{dioData.elevatorInfo ? dioData.elevatorInfo.eleAddress : ''}}
      </el-descriptions-item>
    </el-descriptions>
    <div v-for="(item, index) in signList" :key="item.id">
      <h3 style="color: #fff">维保人员{{index + 1}}:</h3>
      <el-descriptions class="margin-top descriptionsItem" :column="3" border>
        <el-descriptions-item>
          <template slot="label">
            维保人员
          </template>
          {{item.userName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签到时间
          </template>
          {{item.signTime}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签到方式
          </template>
          {{signListInfo.find(idd => idd.value ==item.signType).label}}&emsp;{{item.longitude&&item.latitude? '('+item.longitude+'&emsp;'+item.latitude+')' :""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            维保结束时间
          </template>
          {{item.finishTime}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            维保时长
          </template>
          {{item.taskDuration}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            人脸识别
          </template>
          {{item.verifaceStatus == 0 ? '无' : '有'}}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top descriptionsRow" :column="1" border v-if="item.signType == 3">
        <el-descriptions-item>
          <template slot="label">
            拍照签到照片
          </template>
          <div v-if="item.imgList && item.imgList.split(',').length > 0">
            <el-image v-for="(ids,indexs) in item.imgList.split(',')" class="gdImg" :key="indexs" :src="ids"
              fit="scale-down" :preview-src-list="item.imgList.split(',')">
            </el-image>
          </div>
          <span v-else> - </span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top descriptionsRow" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            维保签字
          </template>
          <div v-if="item.signImg && JSON.parse(item.signImg).length > 0">
            <el-image v-for="(ids,indexs) in JSON.parse(item.signImg)" class="gdImg" :key="indexs" :src="ids"
              fit="scale-down" :preview-src-list="JSON.parse(item.signImg)">
            </el-image>
          </div>
          <span v-else> - </span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <template v-if="dioData.signType == 1">
      <h3 class="changepointer">签到图片:</h3>
      <div style="margin-top: 10px;">
        <template v-for="(itm, index) in dioData.imgList.split(',')">
          <el-image class="gdImg" v-show="itm" :key="index" :src="itm" fit="scale-down"
            :preview-src-list="dioData.imgList.split(',')">
          </el-image>
        </template>
      </div>
    </template>

    <!-- <template v-if="dioData.wbImg && dioData.wbImg.length>0">
			<h3 class="changepointer">维保签字:</h3>
			<div style="margin-top: 10px;">
			<template v-for="(itm, index) in dioData.wbImg">
				<el-image class="gdImg" :key="index" :src="itm" fit="scale-down" :preview-src-list="dioData.wbImg">
				</el-image>
			</template>
			</div>
		</template> -->

    <template v-if="dioData.wyImg && dioData.wyImg.length>0">
      <h3 class="changepointer">物业签字:</h3>
      <div style="margin-top: 10px;">
        <template v-for="(itm, index) in dioData.wyImg">
          <el-image class="gdImg" :key="index" :src="itm" fit="scale-down" :preview-src-list="dioData.wyImg">
          </el-image>
        </template>
      </div>
    </template>

    <div class="tabDiv">
      <h3 class="changepointer">维保项:</h3>
      <template v-if="isShowTab">
        <div v-for="(item,index) in remarkList" :key="index">
          <h4 style="color: #fff;">{{item.value}}:</h4>
          <div v-for="(item,indexx) in item.data" :key="indexx">
            <div class="remark-vessel">
              <span>{{indexx+1}}. {{item.item}}
                <el-button :type="item.status == 1 ? 'primary' : (item.status== 2 ? 'danger' : 'warning')" size="mini"
                  style="margin-left: 15px">{{item.statusName}}</el-button>
              </span>
              <p v-if="item.memo!=''">备注:{{item.memo}}</p>
              <div v-if="item.images.length>0 && item.images != '[]'" style="margin-top: 10px;">
                <template v-for="(itm, indexxx) in item.images">
                  <el-image class="gdImg" v-show="itm" :key="indexxx" :src="itm" fit="scale-down"
                    :preview-src-list="item.images">
                  </el-image>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(item,index) in newRemarkList" :key="index">
          <div class="remark-vessel">
            <span>{{index+1}}. {{item.item}}
              <el-button :type="item.status == 1 ? 'primary' : (item.status== 2 ? 'danger' : 'warning')" size="mini"
                style="margin-left: 15px">{{item.statusName}}</el-button>
            </span>
            <p v-if="item.memo!=''">备注:{{item.memo}}</p>
            <div v-if="item.images.length>0 && item.images != '[]'" style="margin-top: 10px;">
              <template v-for="(itm, index) in item.images">
                <el-image class="gdImg" v-show="itm" :key="index" :src="itm" fit="scale-down"
                  :preview-src-list="item.images">
                </el-image>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dioData: {},
        remarkList: [],
        signList: [],
        orderList: [{
          value: 0,
          label: '待付款'
        }, {
          value: 1,
          label: '待派单'
        }, {
          value: 2,
          label: '待接单'
        }, {
          value: 3,
          label: '已接单'
        }, {
          value: 4,
          label: '待维保'
        }, {
          value: 5,
          label: '已维保'
        }, {
          value: 6,
          label: '已结算'
        }, {
          value: 7,
          label: '已退款'
        }],
        signListInfo: [{
          value: 0,
          label: 'GPS定位'
        }, {
          value: 1,
          label: '扫码签到'
        }, {
          value: 2,
          label: '安全帽签到'
        }, {
          value: 3,
          label: '拍照签到'
        }],

        itemGroup: [{
            label: "1",
            value: "层门"
          },
          {
            label: "2",
            value: "底坑"
          },
          {
            label: "3",
            value: "机房"
          },
          {
            label: "4",
            value: "轿顶"
          },
          {
            label: "5",
            value: "轿厢"
          }
        ],
        activeName: '1',
        newRemarkList: [],
        imagesUrl: '',
        isShowTab: false
      }
    },
    methods: {
      goHis() {
        window.history.go(-1)
      },
      // handleClick(tab, event){
      //   this.remarkList = this.newRemarkList.filter(item => item.itemGroup == this.activeName)
      // },
    },
    created() {
      console.log(223, this.$route)
      // let id = this.$route.params.id.split(',').length > 0 ? this.$route.params.id.split(',')[0] : this.$route.params.id
      // this.type = this.$route.params.id.split(',').length > 0 ? this.$route.params.id.split(',')[1] : 0
      let url1 = '/api/ele/web/maintainTask/getList'
      let url2 = '/api/ele/web/maintainTask/getInfo'
      this.$http
        .post(url2, {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.dioData = res.data.data
            this.dioData.rescueCode = res.data.data.elevatorInfo.rescueCode
            this.dioData.factoryNumber = res.data.data.elevatorInfo.factoryNumber
            this.dioData.longitude = res.data.data.longitude
            this.dioData.latitude = res.data.data.latitude
            // this.dioData.maintainUserNames = res.data.data.maintainUserNames
            this.signList = res.data.data.signList



            let task = res.data.data.taskDetails
            let tasklst = ''

            for (var i = task.length - 1; i >= 0; i--) {
              if (task[i].status == 1 || task[i].status == 2) {
                tasklst = task[i].maintainRecord
                this.dioData.wbImg = task[i].signImg ? JSON.parse(task[i].signImg) : ''
                break;
              } else if (task[i].status == 4 || task[i].status == 5 || task[i].status == 10) {
                // task[i].status == 4 || task[i].status == 5 ||
                this.dioData.wyImg = task[i].signImg ? JSON.parse(task[i].signImg) : ''
              }
            }
            if (tasklst != '') {
              const newTasklst = JSON.parse(tasklst)
              console.log(JSON.parse(tasklst), 'newTasklst.data');
              if (newTasklst.data) {
                this.newRemarkList = JSON.parse(newTasklst.data)
                this.newRemarkList.forEach((qs) => {
                  qs.statusName =
                    qs.status == 1 ? '正常' : qs.status == 2 ? '异常' : '无此项'
                })
                if (this.newRemarkList.find(item => item.itemGroup == 0)) {
                  this.isShowTab = false
                } else {
                  this.isShowTab = true
                }

                this.newRemarkList = this.newRemarkList.sort((a, b) => a.itemGroup - b.itemGroup)
                let remarkList1 = this.newRemarkList.filter(item => item.itemGroup == 1)
                let remarkList2 = this.newRemarkList.filter(item => item.itemGroup == 2)
                let remarkList3 = this.newRemarkList.filter(item => item.itemGroup == 3)
                let remarkList4 = this.newRemarkList.filter(item => item.itemGroup == 4)
                let remarkList5 = this.newRemarkList.filter(item => item.itemGroup == 5)

                this.itemGroup.map(item => {
                  this.remarkList.push({
                    value: item.value,
                    data: this.newRemarkList.filter(ite => ite.itemGroup == item.label)
                  })
                })
              } else {
                this.isShowTab = false
                this.newRemarkList = JSON.parse(tasklst)
                this.newRemarkList.forEach((qs) => {
                  qs.statusName =
                    qs.status == 1 ? '正常' : qs.status == 2 ? '异常' : '无此项'
                })
              }
              // this.remarkList = JSON.parse(tasklst)
              // this.remarkList.forEach((qs) => {
              //   qs.statusName =
              //     qs.status == 1 ? '正常' : qs.status == 2 ? '异常' : '无此项'
              // })
              // console.log(this.remarkList,'this.remarkList')
            }
            setTimeout(() => {
              window.print()
            }, 2000)

          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  }
</script>

<style lang="scss" scoped>
  .codeBox {
    display: flex;
    align-items: center;
  }

  .codeTimeBox {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    padding: 15px 20px;
    width: 35%;
    text-align: center;
    font-size: 16px;
    background: #04345b47;
    color: #fff
  }

  .stamp {
    width: 98%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .gdImg {
    width: 80px;
    height: 80px;
    border: 1px solid #00f6ff;
    margin-right: 20px;
  }

  .changepointer {
    color: #FFF
  }

  .remark-vessel {
    color: white;
    margin-top: 10px;
  }

  .tabDiv {
    padding: 10px;
  }

  .descriptionsRow {
    /deep/.el-descriptions-item__label {
      width: 265px;
    }
  }

  .descriptionsItem {
    /deep/.el-descriptions-item__label {
      width: 150px;
    }

    /deep/.el-descriptions-item__content {
      width: 213px;
    }
  }

  @media print {
    span {
      color: #000;
    }

    .el-descriptions,
    .page {
      color: #000;
    }
  }
</style>
